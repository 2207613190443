import React from "react";
import { WithLogic, withLogicContext } from "../../App";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { TAG_COUNT_MIN } from "../../configs";
import menuJson from "../../settings/menu.json";
import { generateRandomString } from "../../modules/playground/playground-logic";
import { prepareUrl } from "../../utils/string-utils";
import { TagModel } from "../../types/stamps";

// https://codepen.io/victoriak7/pen/xxGGYOE?editors=1100
interface MenuItemModel {
  tag: string;
  label?: string;
  childrens?: MenuItemModel[];
  active?: boolean;
}

interface Props extends WithLogic {
  tags?: TagModel[];
  onChipClick?: (tag_id: string) => void;
  onRandomClick?: (hash: string) => void;
}

function ThemesMenu(props: Props) {
  const navigate = useNavigate();
  const menu = menuJson as MenuItemModel[];

  function onChipClick(label: string) {
    navigate(`/theme/${label.replaceAll("_", "-")}`);
    if (props.onChipClick) {
      props.onChipClick(label);
    }
  }

  function handleRandomClick() {
    const reloadHash = generateRandomString(2);
    props.onRandomClick?.(reloadHash);
  }

  let dbTag: TagModel | undefined = undefined;
  return (
    <>
      <Box className="head-page-mobile">
        <a href="/">WeAreStamps.net</a>
      </Box>
      <div className="nav">
        <nav>
          <label htmlFor="toggle">
            <div className="mobile-menu-trigger">
              <div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </label>

          <input
            type="checkbox"
            id="toggle"
            className="visually-hidden"
          ></input>

          <ul className="menu menu-bar">
            {menu.map((item, i) => (
              <li key={i}>
                <a
                  href={prepareUrl(item.tag)}
                  onClick={() => {
                    !item.active && onChipClick(`${item.tag}`);
                    const checkbox = document.getElementById(
                      "toggle"
                    ) as HTMLInputElement;
                    // set checkbox to false
                    if (checkbox) {
                      checkbox.checked = false;
                    }
                  }}
                  className="menu-link menu-bar-link"
                  aria-haspopup="true"
                >
                  #{item.tag}
                </a>
                <ul className="mega-menu mega-menu--multiLevel">
                  {/* <li className="mobile-menu-back-item">
                    <a
                      href="javascript:void(0);"
                      className="menu-link mega-menu-link"
                    >
                      Back
                    </a>
                  </li> */}
                  {item?.childrens
                    ?.filter((child) => {
                      dbTag = props.tags?.find((d) => d.tag_id === child.tag);
                      return (
                        child.active ||
                        (dbTag?.count && dbTag.count > TAG_COUNT_MIN)
                      );
                    })
                    .map((child, j) => {
                      return (
                        <li key={j}>
                          <a
                            href={prepareUrl(child.tag)}
                            onClick={() => {
                              onChipClick(`${child.tag}`);
                            }}
                            className="menu-link mega-menu-link"
                          >
                            #{child.tag}
                          </a>
                        </li>
                      );
                    })}
                </ul>
              </li>
            ))}
            <li className="random-item">
              <a
                href="/"
                onClick={(e) => {
                  handleRandomClick();
                  e.stopPropagation();
                  e.preventDefault();
                }}
                aria-haspopup="false"
              >
                %random
              </a>
            </li>
            <li id="myElement" className="mobile-menu-header">
              <a
                href="#"
                aria-haspopup="false"
                // href="javascript:void(0);"
                onClick={() => {
                  const checkbox = document.getElementById(
                    "toggle"
                  ) as HTMLInputElement;
                  // set checkbox to false
                  if (checkbox) {
                    checkbox.checked = false;
                  }
                }}
              >
                <span className="menu-close-button" />
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}

export default withLogicContext(ThemesMenu);
