import React from "react";
import { Box, Button, Grid, Chip, Typography } from "@mui/material";

import Attribute from "../Attribute";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { AddCircle, RemoveCircle } from "@mui/icons-material";
import { WithLogic, withLogicContext } from "../../App";
import { stampWidth } from "../../configs";
import {
  generateStampSeoTitle,
  getCountryNameFromCode,
  getImageSrc,
  isDevelopersMode,
  prepareUrl,
} from "../../utils/string-utils";
import { useNavigate } from "react-router-dom";
import StampImage from "../StampImage";
import StampSneak from "../StampSneak";
import { Helmet } from "react-helmet-async";
import { Stamp } from "../../types/stamps";

interface Props extends WithLogic {
  columns: number;
  stamp?: Stamp;
  onButtonBackClick?: () => void;
  onChipClick?: (tag_id: string) => void;
  onStampSneakDevAddCollectionId?: (collectionId: string, _id: string) => void;
  onCollectionImageClick?: (id: string) => void;
  onLoadTagsClick?: (id?: string) => void;
  onDevAddThemeTag?: (tag_id: string) => void;
  onDevAddLabel?: (label: string, id: string) => void;
}

function StampDetail(props: Props) {
  const navigate = useNavigate();
  const pageTitle = generateStampSeoTitle(props.stamp);

  if (props.logic.playground().stampsPreviewed === undefined) {
    return <></>;
  }

  function onButtonBackClick() {
    if (props.logic.playground().tag) {
      navigate(`/theme/${props.logic.playground().tag?.replaceAll("_", "-")}`);
    } else {
      navigate("/");
    }
    props.onButtonBackClick?.();
  }

  function onChipClick(label: string) {
    navigate(`/theme/${label}`);
    if (props.onChipClick) {
      props.onChipClick(label);
    }
  }

  function onStampCollectionClick(stamp: Stamp) {
    navigate(`/stamp/${stamp.timestampField}`);
    props.onCollectionImageClick?.(stamp.id);
  }

  const imgBg = "#fff";
  const textBg = "#fff";

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        {props.stamp && (
          <meta
            name="description"
            content={
              "Stamp " +
              (props.stamp?.nameEn ?? props.stamp?.name) +
              " from " +
              getCountryNameFromCode(props.stamp.country) +
              props.stamp.labels
                ? ": " + props.stamp.labels?.join(", ") + "."
                : "."
            }
          />
        )}
      </Helmet>
      <Box
        className="stamp-detail"
        sx={{
          display: "flex",
          justifyContent: "center",
          background: `linear-gradient(to right, ${imgBg} 50%, ${textBg} 50%)`,
        }}
      >
        <Box
          sx={{
            width: props.columns * stampWidth + props.columns * 32,
          }}
        >
          <Box>
            <Grid container className="stamp-detail-container">
              <Grid
                item
                sm={12}
                md={5}
                className={`stamp-detail-image-half stamp-detail-image-half-${props.stamp?.country}`}
                sx={{ backgroundColor: imgBg }}
                padding={0}
              >
                <Box className="stamp-detail-box">
                  <img
                    className="stamp-detail-image"
                    src={getImageSrc(
                      false,
                      props.stamp?.imgLocal,
                      props.stamp?.country
                    )}
                    style={{
                      minWidth:
                        (props.stamp?.imgWidth ?? 1) >
                        (props.stamp?.imgHeight ?? 1)
                          ? 376
                          : undefined,
                      minHeight:
                        (props.stamp?.imgWidth ?? 1) <
                        (props.stamp?.imgHeight ?? 1)
                          ? 376
                          : undefined,
                      width: "100%",
                    }}
                    alt="stamp"
                  />
                  <br />
                  {isDevelopersMode() && props.stamp && (
                    <StampSneak
                      isLandingPage={false}
                      stamp={props.stamp}
                      // onDevAddThemeTag={this._addThemeTag}
                      onDevAddCollectionId={
                        props.onStampSneakDevAddCollectionId
                      }
                      onLoadTagsClick={props.onLoadTagsClick}
                      onDevAddThemeTag={props.onDevAddThemeTag}
                      onDevAddLabel={props.onDevAddLabel}
                    />
                  )}
                </Box>
              </Grid>
              <Grid
                item
                sm={12}
                md={7}
                className="stamp-detail-info"
                sx={{
                  backgroundColor: textBg,
                  borderLeftColor: textBg,
                }}
              >
                <Box>
                  <Grid container spacing={1}>
                    {props.logic.navBar().logged && (
                      <>
                        <Grid item>
                          <Button variant="outlined" onClick={() => {}}>
                            <FavoriteIcon />
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button variant="outlined" onClick={() => {}}>
                            <AddCircle />
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button variant="outlined" onClick={() => {}}>
                            <RemoveCircle />
                          </Button>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Box>
                <Box className="stamp-detail-info-head">
                  <Grid container spacing={3} wrap="nowrap">
                    {props.logic.playground().stamps.length > 1 && (
                      <Grid item>
                        <Box className="stamp-detail-back-button">
                          <Button
                            variant="outlined"
                            size="medium"
                            onClick={onButtonBackClick}
                          >
                            <ArrowBackIcon />
                          </Button>
                        </Box>
                      </Grid>
                    )}
                    <Grid item>
                      {(props.stamp?.nameEn || props.stamp?.name) && (
                        <Typography className="stamp-detail-name">
                          {props.stamp?.nameEn ?? props.stamp?.name}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Box>
                {props.stamp?._themeTags &&
                props.stamp._themeTags.length > 0 ? (
                  <Attribute
                    label="Themes"
                    value={props.stamp?._themeTags.map((tag, i) => (
                      <a
                        href={prepareUrl(tag.tag_id)}
                        key={i}
                        onClick={(e) => {
                          onChipClick(`${tag.tag_id}`);
                          e.preventDefault();
                          e.stopPropagation();
                        }}
                      >
                        <Chip
                          className="collection-words-chip"
                          key={tag.tag_id}
                          label={tag.label}
                          sx={{
                            borderRadius: "4px",
                          }}
                        />
                      </a>
                    ))}
                  />
                ) : (
                  props.logic.playground().stampDetailedTagsLoading && (
                    <Attribute
                      label="Themes"
                      value={
                        <Chip
                          className="collection-words-chip"
                          label={"              "}
                          sx={{
                            borderRadius: "4px",
                            width: "130px",
                          }}
                        />
                      }
                    />
                  )
                )}
                {props.stamp?.labels && (
                  <Attribute
                    label="Texts on stamp"
                    value={props.stamp.labels.map((label, i) => (
                      <React.Fragment key={i}>
                        {i !== 0 && (
                          <Box component="span" className="unselectable">
                            •
                          </Box>
                        )}
                        <Box component="span">{label}</Box>
                      </React.Fragment>
                    ))}
                  />
                )}
                {props.stamp?.country && (
                  <Attribute
                    label="Country"
                    value={getCountryNameFromCode(props.stamp?.country)}
                  />
                )}
                {props.stamp?.width !== undefined &&
                  props.stamp?.height !== undefined &&
                  props.stamp?.width > 2 &&
                  props.stamp?.height > 2 && (
                    <Attribute
                      label="Size"
                      value={
                        <>
                          {props.stamp?.width} x {props.stamp?.height} mm
                        </>
                      }
                    />
                  )}
                {isDevelopersMode() &&
                  props.stamp?.imgWidth &&
                  props.stamp?.imgWidth && (
                    <Attribute
                      label="Img size [px]"
                      value={
                        <>
                          {props.stamp?.imgWidth} x {props.stamp?.imgHeight} px
                        </>
                      }
                    />
                  )}
                {props.stamp?.printTechniqueEn && (
                  <Attribute
                    label="Print technique"
                    value={props.stamp?.printTechniqueEn}
                  />
                )}
                {props.stamp?.print && (
                  <Attribute label="Printer" value={props.stamp?.print} />
                )}

                {props.stamp?.emission && (
                  <Attribute
                    isNumber={true}
                    label="Emission"
                    value={props.stamp?.emission}
                  />
                )}
                {props.stamp?.perforation && (
                  <Attribute
                    label="Perforation"
                    value={props.stamp?.perforation}
                  />
                )}
                {props.stamp?.paper && (
                  <Attribute label="Paper" value={props.stamp?.paper} />
                )}
                {props.stamp?.date && (
                  <Attribute label="Date of issue" value={props.stamp?.date} />
                )}
                {props.stamp?.nominal && (
                  <Attribute label="Nominal" value={props.stamp?.nominal} />
                )}
                {props.stamp?.author && (
                  <Attribute label="Author" value={props.stamp?.author} />
                )}
                {props.stamp?.designer && (
                  <Attribute label="Designer" value={props.stamp?.designer} />
                )}
                {props.stamp?.illustrator && (
                  <Attribute
                    label="Illustrator"
                    value={props.stamp?.illustrator}
                  />
                )}
                {props.stamp?.engraver && (
                  <Attribute label="Engraver" value={props.stamp?.engraver} />
                )}
                {(props.stamp?.bondingEn ?? props.stamp?.bonding) && (
                  <Attribute
                    label="Bonding"
                    value={props.stamp?.bondingEn ?? props.stamp?.bonding}
                  />
                )}
                {props.stamp?.issuedIn && (
                  <Attribute label="Issued in" value={props.stamp?.issuedIn} />
                )}
                {props.stamp?.wns && (
                  <Attribute label="WNS" value={props.stamp?.wns} />
                )}
                {props.stamp?.series && (
                  <Attribute label="Series" value={props.stamp?.series} />
                )}
                {props.stamp?.themeTags && (
                  <Attribute
                    label="Series"
                    value={props.stamp?.themeTags?.join(", ")}
                  />
                )}

                {props.stamp?.collectionTags && (
                  <Attribute
                    label="Series"
                    value={props.stamp?.collectionTags?.join(", ")}
                  />
                )}

                {props.stamp?.awardsEn && (
                  <Attribute label="Note" value={props.stamp?.awardsEn} />
                )}
                {/* {props.logic.playground().collectionPreviewedLoaded &&
                  props.stamp?.themeDescriptionEn &&
                  !props.logic.playground().collectionPreviewed && (
                    <Attribute
                      label="Description"
                      wrap={true}
                      value={props.stamp?.themeDescriptionEn}
                      html={true}
                      // source={props.stamp?.url ?? props.stamp?.urlEn}
                    />
                  )} */}

                {isDevelopersMode() && (
                  <>
                    {props.stamp?.img && (
                      <Attribute label="IMG URL" value={props.stamp?.img} />
                    )}
                    {props.stamp?.collectionId && (
                      <Attribute
                        label="COLLECTION ID"
                        value={props.stamp?.collectionId}
                      />
                    )}
                    {props.stamp?.url && (
                      <Attribute label="URL" value={props.stamp?.url} />
                    )}
                    {props.stamp?.urlEn && (
                      <Attribute label="URL en" value={props.stamp?.urlEn} />
                    )}
                    {props.stamp?.id && (
                      <Attribute label="ID" value={props.stamp?.id} />
                    )}
                    {props.stamp?._id && (
                      <Attribute label="_ID" value={props.stamp?._id} />
                    )}
                    {props.stamp?.timestampField && (
                      <Attribute
                        label="Timestamp field"
                        value={props.stamp?.timestampField}
                      />
                    )}
                  </>
                )}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>

      {props.logic.playground().collectionPreviewed !== undefined &&
        props.logic.playground().collectionPreviewedLoaded && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                width: props.columns * stampWidth + props.columns * 32,
              }}
            >
              <Grid container className="stamp-detail-collection">
                <Grid item xs={12}>
                  <Typography className="stamp-detail-heading2">
                    Collection:{" "}
                    {props.logic.playground().collectionPreviewed?.label ?? ""}
                  </Typography>
                </Grid>
                <Grid container alignItems="baseline">
                  {props.stamp?._withinCollection?.map((stamp, i) => (
                    <Grid item key={i}>
                      <StampImage
                        stamp={stamp}
                        onImageClick={() => onStampCollectionClick(stamp)}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
              {isDevelopersMode() && (
                <>
                  <hr />
                  <Box>
                    {props.logic.playground().collectionPreviewed?.id}:
                    {props.logic.playground().collectionPreviewed?.label}
                  </Box>
                  <Grid container className="stamp-detail-collection">
                    <Grid container alignItems="baseline">
                      {props.stamp?._withinCollection?.map((stamp, i) => (
                        <Grid item key={i}>
                          <StampSneak
                            isLandingPage={false}
                            stamp={stamp}
                            // onDevAddThemeTag={this._addThemeTag}
                            onDevAddCollectionId={
                              props.onStampSneakDevAddCollectionId
                            }
                            onLoadTagsClick={props.onLoadTagsClick}
                            onDevAddThemeTag={props.onDevAddThemeTag}
                            onDevAddLabel={props.onDevAddLabel}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </>
              )}
            </Box>
          </Box>
        )}
      {props.stamp?.themeDescriptionEn && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
          className="collection-after"
        >
          <Box
            sx={{
              width: props.columns * stampWidth + props.columns * 32,
            }}
          >
            {props.stamp?.themeDescriptionEn && (
              <Attribute
                // label="Description"
                wrap={false}
                value={props.stamp?.themeDescriptionEn}
                html={true}
                // source={props.stamp?.url ?? props.stamp?.urlEn}
                size="large"
              />
            )}
          </Box>
        </Box>
      )}
    </>
  );
}

export default withLogicContext(StampDetail);
