import { Component, createRef } from "react";
import { observer } from "mobx-react";
import { Box } from "@mui/material";
import { WithLogic, withLogicContext } from "../../App";
import {
  defaultPageTitle,
  devCountry,
  developersActions,
  imageDimensions,
  layoutUsageWidth,
  masonrySpacing,
  seoTitleSuffix,
  stampWidth,
} from "../../configs";
import StampSidePanel from "../../components/StampSidePanel";
import { getFileNameFromUrl } from "../../utils/string-utils";
import StampSneak from "../../components/StampSneak";
import DevelopersActions from "../../components/DevelopersActions";
import StampDetail from "../../components/StampDetail";
import ImageDimensions from "../../components/ImageDimensions";
import {
  DocumentReference,
  collection,
  getDocs,
  getFirestore,
  query,
  where,
  writeBatch,
} from "firebase/firestore";
import Loading from "../../components/Loading";
import Footer from "../../components/Footer";
import { Masonry } from "@mui/lab";
import { Helmet } from "react-helmet-async";
import Story from "../../components/Story";
import ThemeHeading from "../../components/ThemeHeading";
import CenterBoxWrapper from "../../components/CenterBoxWrapper";
import { FilterParamsModel } from "../../types/operations";
import menutItems from "../../settings/menu.json";

export enum Color {
  Red = "red",
  Yellow = "yellow",
  Green = "green",
  Blue = "blue",
  Purple = "purple",
  Orange = "orange",
  Black = "black",
}

export enum RouteParamsList {
  Tag = "theme",
  Country = "country",
  Hash = "hash",
  Stamp = "stamp",
}

interface RouteParams {
  list?: RouteParamsList;
  tag?: string;
  stamp?: string;
}

interface Props extends WithLogic {
  routeParams?: RouteParams;
}

interface State {
  filter?: {
    color?: Color;
    year: {
      start: number;
      end: number;
    };
  };
  openIds: string[];
  openCollectionWords: boolean;
  resizing: boolean;
  columns: number;
  hoverId?: string;
  tags?: {
    [key: string]: string;
  };
}

class PlaygroundModule extends Component<Props, State> {
  private resizeTimeout: NodeJS.Timeout | null = null;
  private myRef: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    this.myRef = createRef();

    this.state = {
      openIds: [],
      openCollectionWords: false,
      resizing: false,
      columns: this.calculateColumns(),
    };

    this.resizeTimeout = null;
  }

  async componentDidMount() {
    this.props.logic.playground().init({
      country: devCountry,
      tag: this.props.routeParams?.tag?.replaceAll("-", "_"),
      stamp: this.props.routeParams?.stamp,
      object: this.props.routeParams?.list,
      id: this.props.routeParams?.tag?.replaceAll("-", "_"),
    });
    window.addEventListener("resize", this.handleResizeDebounced);
  }

  async componentDidUpdate() {
    this.executeScroll();
  }

  executeScroll = () => {
    this.myRef && this.myRef.current?.scrollIntoView();
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResizeDebounced);
  }

  calculateColumns = (): number => {
    const pageWidth = document.documentElement.clientWidth;
    const columns = Math.min(
      Math.floor((layoutUsageWidth * pageWidth) / stampWidth),
      5
    );
    return columns;
  };

  handleResizeDebounced = () => {
    this.setState({
      resizing: true,
    });
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }

    this.resizeTimeout = setTimeout(() => {
      this.setState({
        columns: this.calculateColumns(),
        resizing: false,
      });
    }, 500);
  };

  render() {
    const cannonicalUrl = window.location.href;
    const theme = this.props.logic
      .playground()
      .tags.find(
        (d) => d.tag_id === this.props.logic.playground().tagPreviewed
      )?.label;

    let childrensThemes: string[] = [];
    if (theme) {
      menutItems.forEach((item) => {
        item.childrens?.forEach((child) => {
          if (child.tag === this.props.logic.playground().tagPreviewed) {
            childrensThemes = child.childrens.map((d) => d.tag);
          }
        });
      });
    }
    console.log("childrensThemes", childrensThemes);

    const actualChildThemes = this.props.logic
      .playground()
      .tags.filter(
        (tag) =>
          tag.count &&
          tag.count > 0 &&
          tag.tag_id &&
          childrensThemes.includes(tag.tag_id)
      );

    const pageTitle = theme ? "Theme: " + theme : defaultPageTitle;

    return (
      <>
        <div className="res-ref" ref={this.myRef} />
        {/* <CollectionWords
          open={this.props.logic.playground().isOpenCollectionWords}
          onCloseClick={this._onCollectionWordsCloseClick}
          onChipClick={this._onCollectionWordsChipClick}
          onWordsLeave={this._onCollectionWordsCloseClick}
        /> */}

        {this.props.logic.playground().noMatchStamp && (
          <CenterBoxWrapper width={300}>
            <Box sx={{ textAlign: "center" }}>Stamp not found</Box>
          </CenterBoxWrapper>
        )}
        {this.props.logic.playground().stampDetailed ? (
          imageDimensions ? (
            <ImageDimensions
              onSaveDimensions={this.props.logic.playground().saveDimensions}
            />
          ) : (
            <>
              <StampDetail
                columns={this.state.columns}
                stamp={this.props.logic.playground().stampDetailed}
                onButtonBackClick={this._onButtonBackClick}
                onChipClick={this._onCollectionWordsChipClick}
                onStampSneakDevAddCollectionId={this._onDevAddCollectionId}
                onCollectionImageClick={this._onStampCollectionImageClick}
                onLoadTagsClick={this._onLoadTagsClick}
                onDevAddThemeTag={this._addThemeTag}
                onDevAddLabel={this._addLabel}
              />
              <CenterBoxWrapper
                width={
                  this.state.columns * stampWidth +
                  (this.state.columns - 1) * 32
                }
              >
                <Footer
                  columns={this.state.columns}
                  withAboutUs={false}
                  withCountries={false}
                />
              </CenterBoxWrapper>
            </>
          )
        ) : (
          <>
            <Helmet>
              <title>{pageTitle + seoTitleSuffix}</title>
              <link rel="canonical" href={`${cannonicalUrl}`} />
            </Helmet>
            <div className="playground-module">
              {theme && (
                <CenterBoxWrapper
                  width={
                    this.state.columns * stampWidth +
                    (this.state.columns - 1) * 32
                  }
                >
                  <ThemeHeading />
                </CenterBoxWrapper>
              )}
              <>
                {(this.props.logic.playground().loading ||
                  this.state.resizing) && <Loading />}

                {this.props.logic.playground().list === "story" ? (
                  <Story />
                ) : (
                  <Box
                    className="results"
                    sx={{
                      marginTop: `${
                        this.props.logic.playground().loading
                          ? "calc(-100% - 15px)"
                          : "0"
                      }`,
                    }}
                  >
                    <Masonry
                      columns={Math.min(
                        this.state.columns,
                        this.props.logic.playground().stamps.length
                      )}
                      spacing={masonrySpacing}
                    >
                      {this.props.logic
                        .playground()
                        .stamps.map((stamp, index) => (
                          <>
                            {actualChildThemes.length > 0 &&
                              index === this.state.columns - 1 && (
                                <nav className="sneak-menu">
                                  <ul className="mega-menu mega-menu--multiLevel">
                                    {actualChildThemes.map((tag) => (
                                      <li key={tag.tag_id}>
                                        <a
                                          href={`#${tag.tag_id}`}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            this._onCollectionWordsChipClick(
                                              tag.tag_id
                                            );
                                          }}
                                          className="menu-link mega-menu-link"
                                        >
                                          {tag.label.toUpperCase()}
                                        </a>
                                      </li>
                                    ))}
                                  </ul>
                                </nav>
                              )}

                            <div key={index}>
                              <StampSneak
                                index={index}
                                isLandingPage={
                                  this.props.logic.playground().landing
                                }
                                key={index}
                                stamp={stamp}
                                onDevAddThemeTag={this._addThemeTag}
                                onDevAddColor={
                                  this.props.logic.playground().addColor
                                }
                                onDevAddCollectionId={
                                  this._onDevAddCollectionId
                                }
                                onImageClick={
                                  this._onStampSneakDetailButtonClick
                                }
                                // onImageClick={this._onStampImageClick}
                                onAddToFavouritesButtonClick={
                                  this._onAddToFavouritesButtonClick
                                }
                                onAddToAlbumButtonClick={
                                  this._onAddToAlbumButtonClick
                                }
                                onRemoveFromAlbumButtonClick={
                                  this._onRemoveFromAlbumButtonClick
                                }
                                // onDetailButtonClick={this._onStampSneakDetailButtonClick}
                                onDetailButtonClick={this._onStampImageClick}
                                onChangeWidthHeight={this._onChangeWidthHeight}
                                onLoadTagsClick={this._onLoadTagsClick}
                                onDevAddLabel={this._addLabel}
                                onNameChange={
                                  this.props.logic.playground()
                                    .onStampNameUpdate
                                }
                              />
                            </div>
                          </>
                        ))}
                    </Masonry>
                  </Box>
                )}
              </>
              {theme && (
                <CenterBoxWrapper
                  width={
                    this.state.columns * stampWidth +
                    (this.state.columns - 1) * 32
                  }
                >
                  <ThemeHeading />
                </CenterBoxWrapper>
              )}

              <CenterBoxWrapper
                width={
                  this.state.columns * stampWidth +
                  (this.state.columns - 1) * 32
                }
              >
                <Footer
                  columns={this.state.columns}
                  withAboutUs={true}
                  withCountries={true}
                />
              </CenterBoxWrapper>
            </div>
            <StampSidePanel
              stampsDetailed={this.props.logic.playground().stampsPreviewed}
              onStampDetailCloseClick={this._onStampPreviewCloseClick}
            />
            {developersActions && (
              <DevelopersActions
                onDuplicateParameterToConsoleString={
                  this._duplicateParameterToConsoleString
                }
                onExportJSONImgToString={this._exportJSONImgToString}
                onAddJSONToDbClick={this._addJSONToDb}
                onExportJSONClick={this._exportJSON}
                onFetchEnNameForSkClick={this._fetchEnNameForSk}
                onAddTimestampClick={this._onAddTimestampClick}
                onCopyIdTo_IdClick={this._onCopyIdTo_IdClick}
                onRenameColumnClick={this._onRenameColumnClick}
                onGenerateCollectionsClick={
                  this.props.logic.playground().fetchStampsAndUpdateCollectionId
                }
                operationTags={this.props.logic.playground().operationTags}
              />
            )}
          </>
        )}
      </>
    );
  }

  private _addThemeTag = (tag: string, stamp_id?: string) => {
    stamp_id &&
      this.props.logic.playground().addCollectionTagSeparate(stamp_id, tag);
  };

  private _addLabel = (label: string, stamp_id?: string) => {
    stamp_id && this.props.logic.playground().addLabel(label, stamp_id);
  };

  private _duplicateParameterToConsoleString = () => {
    this.props.logic.playground().duplicateParameterToConsoleString();
  };

  private _exportJSONImgToString = () => {
    this.props.logic.playground().exportJSONImgToString();
  };

  private _addJSONToDb = () => {
    this.props.logic.playground().addJSONToDb();
  };

  private _onReloadClick = (hash: string) => {
    this.props.logic.playground().reload(hash);
  };

  private _onAddTimestampClick = () => {
    this.props.logic.playground().addTimestampField();
  };

  private _onCopyIdTo_IdClick = () => {
    this.props.logic.playground().onCopyIdTo_IdClick();
  };

  private _onRenameColumnClick = () => {
    this.props.logic.playground().onRenameColumnClick();
  };

  private _fetchEnNameForSk = () => {
    const stamp = this.props.logic.playground().stamps[0];
    console.log("fetch", stamp.urlEn);

    const enUrl = stamp.urlEn;

    if (enUrl) {
      fetch(enUrl)
        .then((res) => res.text())
        .then(
          (_file) => {
            // const { parse } = require("node-html-parser");
            // const root = parse(file);
            // const text = root.querySelector(".basic-info .title").text;
          },
          (error) => {
            console.log("Error: ", error);
          }
        );
    }
  };

  private _exportJSON = () => {
    const x = this.props.logic.playground().stamps.map((stamp) => ({
      ...stamp,
      _id: stamp.id,
      imgLocal: getFileNameFromUrl(stamp.img ?? ""),
    }));
    console.log(JSON.stringify(x));
  };

  private _onStampPreviewCloseClick = () => {
    this.props.logic.playground().togglePreview();
  };

  // private _onCollectionWordsCloseClick = () => {
  //   this.props.logic.playground().toggleCollectionWords();
  // };

  private _onCollectionWordsChipClick = (code: string) => {
    // const path = window.location.hash.split("/")[1] ?? "";
    this.props.logic
      .playground()
      .loadStamps({ tag: code, page: 0 } as FilterParamsModel);
  };

  private _onAddToFavouritesButtonClick = () => {};

  private _onAddToAlbumButtonClick = () => {};

  private _onRemoveFromAlbumButtonClick = () => {};

  private _onStampImageClick = (stampId?: string) => {
    if (stampId) {
      // this.props.logic.playground().toggleDetailedStamp(stampId);
      this.props.logic.playground().togglePreview(stampId);
    }
  };

  private _onLoadTagsClick = (stampId?: string) => {
    if (stampId) {
      this.props.logic.playground().loadTags(stampId);
    }
  };

  private _onStampCollectionImageClick = (stampId?: string) => {
    if (stampId) {
      this.props.logic.playground().onCollectionImageClick(stampId);
    }
  };

  private _onDevAddCollectionId = async (collectionId: string, id: string) => {
    console.log("add collectionId", collectionId, id);
    if (collectionId !== "" && id !== "") {
      // console.log("step", this.props.logic.playground().stamps);
      // const stampToUpdate = this.props.logic
      //   .playground()
      //   .stamps.find((d) => d._id === id);

      // console.log(
      //   "prog",
      //   this.props.logic.playground().stamps,
      //   toJS(stampToUpdate)
      // );

      // if (stampToUpdate) {
      const db = getFirestore();
      const colRef = collection(db, "stamps");

      const q = query(colRef, where("_id", "==", id));
      const querySnapshot = await getDocs(q);
      console.log("inupdate ", q);
      console.log("inupdate2 ", querySnapshot);
      const batch = writeBatch(db);
      querySnapshot.forEach((doc) => {
        const docRef: DocumentReference = doc.ref;

        batch.update(docRef, {
          collectionId: collectionId,
        });
      });

      console.log("COLLECTION_ID changed", id);
      await batch.commit();
      // } else {
      //   console.log("not found", id);
      // }
    }
  };

  /**
   * If width and height is not set, it will change width to height and height to width (swap)
   *
   */
  private _onChangeWidthHeight = async (
    id?: string,
    width?: number,
    height?: number
  ) => {
    if (id !== undefined) {
      const stampToUpdate = this.props.logic
        .playground()
        .stamps.find((d) => d._id === id);

      if (stampToUpdate) {
        const db = getFirestore();
        const colRef = collection(db, "stamps");

        const q = query(colRef, where("_id", "==", id));
        const querySnapshot = await getDocs(q);
        const batch = writeBatch(db);
        querySnapshot.forEach((doc) => {
          const docRef: DocumentReference = doc.ref;

          batch.update(docRef, {
            height: height ?? stampToUpdate.width,
            width: width ?? stampToUpdate.height,
          });
        });

        console.log("changed", id);
        await batch.commit();
      } else {
        console.log("not found", id);
      }
    }
  };

  private _onStampSneakDetailButtonClick = (stampId?: string) => {
    this.props.logic.playground().toggleDetailedStamp(stampId);
  };

  private _onButtonBackClick = () => {
    this.props.logic.playground().toggleDetailedStamp();
  };
}

export default withLogicContext(observer(PlaygroundModule));
