import { useState, useEffect } from "react"; // Import necessary modules

function PrivacyPolicy() {
  const [fileContent, setFileContent] = useState("");

  useEffect(() => {
    // Load the HTML file content when the component mounts
    fetch("/privacy-policy.html") // Assuming the file is in the public directory
      .then((response) => {
        console.log(response);
        return response.text(); // Add return statement here
      })
      .then((data) => {
        console.log(data);
        setFileContent(data);
      })
      .catch((error) => console.error("Error loading file:", error));
  }, []);

  return (
    <div>
      <div dangerouslySetInnerHTML={{ __html: fileContent }} />
    </div>
  );
}

export default PrivacyPolicy;
