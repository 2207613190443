import { Box, Button, Grid } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";

interface Props {
  bgColor?: string;
  bottomClose: boolean;
  onCloseClick?: () => void;
}

function BoxClose(props: Props) {
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
    >
      <Button
        variant="text"
        onClick={props.onCloseClick}
        className={
          props.bottomClose ? "box-close box-close-bottom" : "box-close"
        }
      >
        <ClearIcon />
      </Button>
    </Grid>
  );
}

export default BoxClose;
