import { makeObservable, observable } from "mobx";
import { Logic } from "../../logic";

export class NavBarLogic {
  @observable logged: boolean;

  constructor(private _logic: Logic) {
    this.logged = localStorage.getItem("name") ? true : false;

    makeObservable(this);
  }

  async login() {
    this.logged = true;
  }

  async logout() {
    localStorage.clear();
    this.logged = false;
  }
}
