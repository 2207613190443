import { PlaygroundLogic } from "./modules/playground/playground-logic";
import { NavBarLogic } from "./modules/navbar/navbar-logic";

export class Logic {
  private _playgroundLogic?: PlaygroundLogic;
  private _navBarLogic?: NavBarLogic;

  constructor() {}

  playground() {
    if (!this._playgroundLogic) {
      this._playgroundLogic = new PlaygroundLogic(this);
    }
    return this._playgroundLogic;
  }

  navBar() {
    if (!this._navBarLogic) {
      this._navBarLogic = new NavBarLogic(this);
    }
    return this._navBarLogic;
  }
}
