import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import {
  Card,
  CardMedia,
  Typography,
  IconButton,
  Box,
  CardContent,
} from "@mui/material";

interface Props {}

function Story(props: Props) {
  return (
    <>
      <h1 className="story-heading">Story of Birds</h1>
      <div className="theme-intro">
        Birds, belonging to the class Aves, are a diverse group of feathered,
        warm-blooded vertebrates, with over 10,000 identified species worldwide.
        Their remarkable variation in size, color, and behavior is a testament
        to their adaptability and evolutionary success. While the ability to fly
        is a defining characteristic, some birds, such as ostriches and
        penguins, have adapted to different lifestyles, forfeiting flight but
        retaining essential avian features. Birds play crucial roles in
        ecosystems as pollinators, seed dispersers, and pest controllers,
        contributing to the balance of nature. Their fascinating behaviors, from
        intricate mating rituals to complex songs, continue to captivate the
        admiration of bird enthusiasts worldwide.
      </div>
      <Timeline position="alternate">
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Card sx={{ display: "flex" }}>
              <CardMedia
                component="img"
                sx={{ width: 151 }}
                image="/stamps/cs/2981.jpg"
                alt="Live from space album cover"
              />
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <CardContent sx={{ flex: "1 0 auto" }}>
                  <Typography component="div" variant="h5">
                    Live From Space
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    component="div"
                  >
                    Mac Miller
                  </Typography>
                </CardContent>
                <Box
                  sx={{ display: "flex", alignItems: "center", pl: 1, pb: 1 }}
                >
                  Text
                </Box>
              </Box>
            </Card>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>Code</TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>Sleep</TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot />
          </TimelineSeparator>
          <TimelineContent>Repeat</TimelineContent>
        </TimelineItem>
      </Timeline>
      <div className="theme-intro">
        Birds, belonging to the class Aves, are a diverse group of feathered,
        warm-blooded vertebrates, with over 10,000 identified species worldwide.
        Their remarkable variation in size, color, and behavior is a testament
        to their adaptability and evolutionary success. While the ability to fly
        is a defining characteristic, some birds, such as ostriches and
        penguins, have adapted to different lifestyles, forfeiting flight but
        retaining essential avian features. Birds play crucial roles in
        ecosystems as pollinators, seed dispersers, and pest controllers,
        contributing to the balance of nature. Their fascinating behaviors, from
        intricate mating rituals to complex songs, continue to captivate the
        admiration of bird enthusiasts worldwide.
      </div>
    </>
  );
}

export default Story;
