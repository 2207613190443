import React from "react";
import { CardActions, Grid, IconButton } from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ArticleIcon from "@mui/icons-material/Article";
import { AddCircle, RemoveCircle, RotateLeft } from "@mui/icons-material";
import { WithLogic, withLogicContext } from "../../App";
import { developersModeSimple } from "../../configs";
import { Stamp } from "../../types/stamps";

export const ENV_FILESERVER = "/";

interface Props extends WithLogic {
  stamp?: Stamp;
  onAddToAlbumButtonClick?: () => void;
  onRemoveFromAlbumButtonClick?: () => void;
  onAddToFavouritesButtonClick?: () => void;
  onDetailButtonClick?: (id?: string) => void;
  onChangeWidthHeight?: (_id: string, width?: number, height?: number) => void;
}

function StampCardActions(props: Props) {
  return (
    <CardActions disableSpacing>
      <Grid container justifyContent="space-between">
        <Grid item>
          {props.logic.navBar().logged && (
            <>
              <IconButton
                size="small"
                aria-label="add to album"
                onClick={props.onAddToAlbumButtonClick}
              >
                <AddCircle />
              </IconButton>
              <IconButton
                size="small"
                aria-label="remove from album"
                onClick={props.onRemoveFromAlbumButtonClick}
              >
                <RemoveCircle />
              </IconButton>
            </>
          )}
        </Grid>
        <Grid item>
          {props.logic.navBar().logged && (
            <IconButton
              size="small"
              aria-label="add to favorites"
              onClick={props.onAddToFavouritesButtonClick}
            >
              <FavoriteIcon />
            </IconButton>
          )}

          {developersModeSimple && (
            <IconButton
              onClick={(e) => {
                if (props.stamp?._id) {
                  props.onChangeWidthHeight?.(props.stamp._id);
                }
                e.stopPropagation();
              }}
            >
              <RotateLeft />
            </IconButton>
          )}
          <IconButton
            size="small"
            aria-label="detail"
            onClick={() => {
              props.onDetailButtonClick?.(props.stamp?._id);
            }}
          >
            <ArticleIcon />
          </IconButton>
        </Grid>
      </Grid>
    </CardActions>
  );
}

export default withLogicContext(StampCardActions);
