import imgLogo from "../../images/img-logo.png";

interface Props {}

function ImgLogo(props: Props) {
  return (
    <div className="img-logo">
      {/* <div className="container">
        <div className="item">
          <div className="wrap">
            <div className="postage postage-90"></div>

            <div className="postage">
              <div className="corner corner-1"></div>
              <div className="corner corner-2"></div>
              <div className="corner corner-3"></div>
              <div className="corner corner-4"></div>
              <div className="inner pattern">
                <div className="price">
                  WE <br />
                  ARE <br />
                  STAMPS
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <img src={imgLogo} width="66" height="66" alt="logo" />
    </div>
  );
}

export default ImgLogo;
