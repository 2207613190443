import imgLoading from "../../loading.svg";

interface Props {}

function Loading(props: Props) {
  return (
    <div className="loading">
      <img src={imgLoading} alt="" />
    </div>
  );
}

export default Loading;
