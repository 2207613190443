import { Stamp } from "../../types/stamps";
import StampDetail from "../StampPreview";

interface Props {
  stampsDetailed: Stamp[];
  onStampDetailCloseClick: () => void;
}

function StampSidePanel(props: Props) {
  return (
    <>
      {props.stampsDetailed.length > 0 && (
        <div className="stamp-side-panel">
          {props.stampsDetailed.map((stamp) => (
            <StampDetail
              key={stamp._id}
              stamp={stamp}
              onStampDetailClick={props.onStampDetailCloseClick}
            />
          ))}
        </div>
      )}
    </>
  );
}

export default StampSidePanel;
