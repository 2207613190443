import { AppBar, Box, Button, Grid } from "@mui/material";
import { Component } from "react";
import CollectionsBookmarkIcon from "@mui/icons-material/CollectionsBookmark";
import { WithLogic, withLogicContext } from "../../App";
import {
  dbLimitThemes,
  localJson,
  loginEnabled,
  showLogo,
  themesBlockIcon,
} from "../../configs";
import { signInWithGoogle } from "../..";
import { observer } from "mobx-react";
import LoginDialog from "../../components/LoginDialog";
import CollectionWords from "../../components/CollectionWords";
import ImgLogo from "../../components/ImgLogo";
import ThemesMenu from "../../components/ThemesMenu";
import { FilterParamsModel } from "../../types/operations";

interface Props extends WithLogic {}
interface State {
  stampsPriceTotal: number;
  stampsCount: number;
  stampsUniqueCount: number;
  loginDialogOpen: boolean;
}

const countries = [
  { value: "ae-aj", label: "Ajman", db: true },
  { value: "sk", label: "Slovakia", db: true },
  { value: "cz", label: "Czechia", db: true },
  { value: "hu", label: "Hungary", db: false },
  { value: "pl", label: "Poland", db: true },
  { value: "at", label: "Austria", db: false },
  { value: "it", label: "Italy", db: false },
  { value: "ax", label: "Aland", db: true },
  { value: "ad", label: "Andorra", db: true },
  { value: "tg", label: "Togo", db: true },
  { value: "fr", label: "France", db: true },
  { value: "sl", label: "Slovenie", db: true },
  { value: "nl", label: "Nederland", db: true },
];

class NavbarModule extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      stampsPriceTotal: 0,
      stampsCount: 0,
      stampsUniqueCount: 0,
      loginDialogOpen: false,
    };
  }

  render() {
    const paginationLength = Math.ceil(
      this.props.logic.playground().stampsTagsIds.length / dbLimitThemes
    );
    const theme = this.props.logic
      .playground()
      .tags.find(
        (d) => d.tag_id === this.props.logic.playground().tagPreviewed
      )?.label;

    return (
      <>
        <Box
        // sx={{ flexGrow: 1 }}
        >
          <AppBar className="navbar">
            <Box className="first-row">
              <Box>
                <Grid container alignItems="center">
                  {showLogo && (
                    <Grid item>
                      <a href="/">
                        <ImgLogo />
                      </a>
                    </Grid>
                  )}
                  <Grid
                    className="head-page-desktop"
                    item
                    sx={{ fontSize: "24px", fontWeight: 300 }}
                  >
                    <Box className="logo">
                      <a href="/">WeAreStamps.net</a>
                    </Box>
                  </Grid>

                  {themesBlockIcon && (
                    <Grid item>
                      <Button
                        variant="text"
                        onClick={this._onCollectionFilterClick}
                      >
                        <CollectionsBookmarkIcon />
                      </Button>
                    </Grid>
                  )}

                  {localJson && (
                    <>
                      <Grid item>
                        <Grid container spacing={1} alignItems="center">
                          {countries
                            .filter((d) => d.db)
                            .map((d, i) => (
                              <Grid item key={i}>
                                <Button
                                  onClick={() => this._onCountryClick(d.value)}
                                >
                                  {d.value}
                                </Button>
                              </Grid>
                            ))}
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Grid container spacing={1} alignItems="center">
                          {countries
                            .filter((d) => !d.db)
                            .map((d, i) => (
                              <Grid item key={i}>
                                <Button
                                  onClick={() => this._onCountryClick(d.value)}
                                >
                                  {d.value}
                                </Button>
                              </Grid>
                            ))}
                        </Grid>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Box>
              {/* <Box className="reload-button-box">
                <Grid item className="first-row-reload">
                  <ReloadButton onClick={this._onReloadClick} />
                </Grid>
              </Box> */}
              {loginEnabled && (
                <Box>
                  {this.props.logic.navBar().logged ? (
                    <Grid
                      sx={{ height: "72px" }}
                      container
                      alignItems="center"
                      spacing={1}
                      alignContent="center"
                    >
                      <Grid item>
                        <img
                          width="36"
                          src={localStorage.getItem("photoUrl") ?? ""}
                          alt="profile"
                          style={{ borderRadius: "50%" }}
                        />
                      </Grid>
                      <Grid item>
                        <Button
                          onClick={() => this.props.logic.navBar().logout()}
                        >
                          Sign out
                        </Button>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid
                      sx={{ height: "72px" }}
                      container
                      alignItems="center"
                      spacing={1}
                      alignContent="center"
                    >
                      <Grid item>
                        <Button
                          onClick={() =>
                            this.setState({ loginDialogOpen: true })
                          }
                        >
                          Login
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                  <LoginDialog
                    open={this.state.loginDialogOpen}
                    onGoogleClick={this._onGoogleClick}
                    onClose={() => this.setState({ loginDialogOpen: false })}
                  />
                </Box>
              )}
            </Box>
            {/* <Box className="second-row">
              <ThemesBar
                tags={this.props.logic.playground().tags}
                onChipClick={this._onCollectionWordsChipClick}
                onRandomClick={this._onReloadClick}
              />
            </Box> */}
            <Box className="second-row">
              <ThemesMenu
                tags={this.props.logic.playground().tags}
                onChipClick={this._onCollectionWordsChipClick}
                onRandomClick={this._onReloadClick}
              />
            </Box>
          </AppBar>

          <CollectionWords
            open={this.props.logic.playground().isOpenCollectionWords}
            onCloseClick={this._onCollectionWordsCloseClick}
            onChipClick={this._onCollectionWordsChipClick}
            onWordsLeave={this._onCollectionWordsCloseClick}
          />
        </Box>
      </>
    );
  }

  private _onCollectionWordsChipClick = (code: string) => {
    console.log("code", code);
    // const path = window.location.hash.split("/")[1] ?? "";
    this.props.logic
      .playground()
      .loadStamps({ tag: code, page: 0 } as FilterParamsModel);
    this.props.logic.playground().closeCollectionWords();
  };

  private _onCollectionWordsCloseClick = () => {
    this.props.logic.playground().closeCollectionWords();
  };

  private _onGoogleClick = () => {
    signInWithGoogle();
    this.setState({ loginDialogOpen: false });
  };

  private _onCollectionFilterClick = () => {
    console.time("toggleCollectionWords");
    this.props.logic.playground().toggleCollectionWords();
    console.timeEnd("toggleCollectionWords");
  };

  private _onReloadClick = (hash: string) => {
    this.props.logic.playground().reload(hash);
  };

  private _onCountryClick = (country: string) => {
    this.props.logic.playground().init({ country });
  };
}

export default withLogicContext(observer(NavbarModule));
