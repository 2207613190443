import { Box, Grid } from "@mui/material";
import { Fragment, ReactNode } from "react";

interface Props {
  label?: string;
  value?: string | number | ReactNode;
  size?: "small" | "medium" | "large";
  html?: boolean;
  wrap?: boolean;
  isNumber?: boolean;
  source?: string;
}

function Attribute(props: Props) {
  const valueNodes = [String(props.value)];

  if (props.isNumber && typeof props.value !== "number") return <></>;

  return (
    <>
      <Grid
        className="attribute"
        container
        wrap={props.wrap ? "wrap" : "nowrap"}
      >
        {props.label && (
          <Grid item>
            <Box className={`attribute-label`}>
              {props.label}
              {props.size !== "large" && ":"}
            </Box>
          </Grid>
        )}
        <Grid item>
          <Box className={`attribute-value attribute-${props.size}`}>
            {props.value &&
              (props.html ? (
                <>
                  {valueNodes.map((line, index) => (
                    <Fragment key={index}>
                      <div
                        contentEditable="false"
                        dangerouslySetInnerHTML={{
                          __html: line
                            .replaceAll("\\n\\n\\n", "  ")
                            .replaceAll("\\n\\n", "  ")
                            .replaceAll("\\n", "  ")
                            .replaceAll(/\s{5}/g, "    ")
                            .replaceAll(/\s{4}/g, "   ")
                            .replaceAll(/\s{3}/g, "  ")
                            .replaceAll(/\s{2}/g, "<br /><br />"),
                        }}
                      ></div>
                      <br />
                      <br />
                    </Fragment>
                  ))}
                </>
              ) : props.isNumber ? (
                Number(props.value).toLocaleString("en", {
                  minimumFractionDigits: 0,
                })
              ) : (
                props.value
              ))}
          </Box>
        </Grid>
      </Grid>
      {props.source && (
        <Grid item>
          <Box>
            <Box className={`attribute-label`}>SOURCE:</Box>
            {props.source}
          </Box>
        </Grid>
      )}
    </>
  );
}

export default Attribute;
