import { HelmetProvider } from "react-helmet-async";
import "./App.sass";
import PlaygroundModule, {
  RouteParamsList,
} from "./modules/playground/PlaygroundModule";

import NavbarModule from "./modules/navbar/NavbarModule";
import { Logic } from "./logic";
import React, { Suspense } from "react";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Terms from "./components/Terms";

const logic = new Logic();
export const LogicContext = React.createContext<Logic>(logic);

export function useLogic() {
  return React.useContext(LogicContext);
}

export type WithLogic = {
  logic: Logic;
};

export function withLogicContext<P extends WithLogic>(
  Component: React.ComponentType<P>
): React.ComponentType<Omit<P, keyof WithLogic>> {
  return function BoundComponent(props: Omit<P, keyof WithLogic>) {
    return (
      <LogicContext.Consumer>
        {(value) => <Component {...(props as unknown as P)} logic={value} />}
      </LogicContext.Consumer>
    );
  };
}

interface Props {
  logic: Logic;
}

interface State {}

class App extends React.Component<Props, State> {
  componentWillUnmount() {}

  componentDidMount(): void {}

  render() {
    const { logic } = this.props;
    const hashSplit = window.location.href.split("/");
    const list = hashSplit[hashSplit.length - 2] ?? "";
    const path = hashSplit[hashSplit.length - 1] ?? "";
    console.log("w", hashSplit, window.location.href, list, path);

    const helmetContext = {};
    console.log("App render", list, path);

    return (
      <HelmetProvider context={helmetContext}>
        <LogicContext.Provider value={logic}>
          <Suspense fallback="Loading...">
            <Router>
              <div className="App">
                <NavbarModule />
              </div>
              <Routes>
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms" element={<Terms />} />

                <Route
                  path="*"
                  element={
                    <PlaygroundModule
                      routeParams={{
                        list: (list as RouteParamsList) ?? "",
                        tag: path ?? "",
                      }}
                    />
                  }
                />
              </Routes>
            </Router>
          </Suspense>
        </LogicContext.Provider>
      </HelmetProvider>
    );
  }
}

export default App;
