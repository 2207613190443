export const confDefault = {
  playground: {
    stampDetail: {
      bgColor: "#000",
    },
    collectionWords: {
      bgColor: "#7FFF00",
    },
  },
};
