import { makeObservable, observable } from "mobx";
import lastStamps from "../../api/phnt500-999nap.json";
import menutItems from "../../settings/menu.json";

import { Logic } from "../../logic";
import {
  DocumentData,
  DocumentReference,
  QuerySnapshot,
  addDoc,
  collection,
  getDocs,
  getFirestore,
  limit,
  orderBy,
  query,
  startAt,
  deleteDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import {
  dbLimit,
  dbLimitHomepage,
  dbLimitStart,
  dbLimitThemes,
  feRandomSort,
  hash,
  localJson,
  localJsonStampLimitEnd,
  localJsonStampLimitStart,
} from "../../configs";
import { randomSort } from "../../utils/math.utils";
import { Collection, Stamp, TagModel } from "../../types/stamps";
import { FilterParamsModel } from "../../types/operations";

export class PlaygroundLogic {
  @observable list: "country" | "countryName" | "theme" | "hash" | "story";
  @observable tag?: string;
  @observable pageIndex: number;
  @observable stamps: Stamp[];
  @observable stampsTagsIds: string[];
  @observable stampsPreviewed: Stamp[];
  @observable collectionPreviewed?: Collection;
  @observable collectionPreviewedLoaded?: boolean;
  @observable stampDetailed?: Stamp;
  @observable stampDetailedTagsLoading: boolean;
  @observable tags: TagModel[];
  @observable isOpenCollectionWords: boolean;
  @observable hash: string = "";
  @observable tagPreviewed?: string;
  @observable loading: boolean;
  @observable landing: boolean;
  @observable noMatchStamp: boolean;
  @observable noMatchTheme: boolean;
  appReady: boolean;

  constructor(private _logic: Logic) {
    this.stamps = [];
    this.stampsPreviewed = [];
    this.tags = [];
    this.isOpenCollectionWords = false;
    this.pageIndex = 0;
    this.list = "hash";
    this.stampsTagsIds = [];
    this.loading = false;
    this.appReady = false;
    this.landing = true;
    this.stampDetailedTagsLoading = false;
    this.noMatchStamp = false;
    this.noMatchTheme = false;

    makeObservable(this);
  }

  async init(params: FilterParamsModel) {
    console.log("INIT");
    this.noMatchStamp = false;
    this.noMatchTheme = false;

    if (this.appReady === false) {
      this.appReady = true;
      await this._fetchTags();
      if (params.object === "stamp" && params.id) {
        this._fetchStamp(params.id);
      } else {
        await this._fetchStamps(params);
      }
    }
  }

  toggleCollectionWords() {
    this.isOpenCollectionWords = !this.isOpenCollectionWords;
  }

  closeCollectionWords() {
    this.isOpenCollectionWords = false;
  }

  openCollectionWords() {
    this.isOpenCollectionWords = true;
  }

  async reload(reloadHash: string) {
    console.log("RELOAD");
    // TODO: get it back
    this.list = "hash";
    console.log("list to story");
    // this.list = "story";
    this.tag = undefined;
    this.tagPreviewed = undefined;
    this.noMatchStamp = false;
    this.noMatchTheme = false;
    if (hash === "") {
      this.hash = reloadHash;
    } else {
      this.hash = hash;
    }

    await this._fetchStamps({ country: "", tag: "", page: 0 });
    window.scrollTo && window.scrollTo(0, 0);
  }

  // TODO:
  async showStory() {
    this.list = "story";
  }

  async toggleDetailedStamp(stampId?: string) {
    if (stampId) {
      this.stampDetailedTagsLoading = true;
      this.stampDetailed = this.stamps.find((stamp) => stamp.id === stampId);
      // this._fetchBg();
      this._fetchStampTags();

      if (this.stampDetailed?.collectionId) {
        console.log("collectionId", this.stampDetailed?.collectionId);

        // collectionId is in format cs_123-1, we need to get only cs_1
        const collectionIdStart =
          this.stampDetailed?.collectionId.split("-")[0];
        this._fetchCollection(collectionIdStart);
        this._fetchCollectionsStamps(this.stampDetailed?.collectionId);
      }
    } else {
      this.stampDetailed = undefined;
      this.collectionPreviewed = undefined;
      this.collectionPreviewedLoaded = false;
      this.stampDetailedTagsLoading = false;
      this.noMatchStamp = false;
      this.noMatchTheme = false;
    }
  }

  async togglePreview(stampId?: string) {
    if (!stampId) {
      console.log("togglePreview delete", stampId);
      this.stampsPreviewed = [];
      return;
    }

    if (this.stampsPreviewed.map((stamp) => stamp.id).includes(stampId)) {
      this.stampsPreviewed = this.stampsPreviewed.filter(
        (stamp) => stamp.id !== stampId
      );
      return;
    } else {
      const stampDetailed = this.stamps.find((stamp) => stamp.id === stampId);
      if (stampDetailed) {
        this.stampsPreviewed = [stampDetailed];
      }
    }
  }

  async _fetchJSONStamps() {
    const lastRaw = lastStamps as Stamp[];

    const at = lastRaw.map((stamp) => ({
      ...stamp,
    }));

    this.stamps = [...at].filter(
      (d, i) => i >= localJsonStampLimitStart && i < localJsonStampLimitEnd
    );
  }

  async _fetchStampTags() {
    const db = getFirestore();
    const colRefStamps2Tags = collection(db, "stamps2tags");
    const colRefTags = collection(db, "tags");

    const q = query(
      colRefStamps2Tags,
      where("stamp_id", "==", this.stampDetailed?.id)
    );

    getDocs(q)
      .then((snapshot) => {
        let tags: string[] = [];
        snapshot.forEach((doc) => {
          tags.push(doc.data().tag_id);
        });

        if (tags.length > 0) {
          const q = query(colRefTags, where("tag_id", "in", tags));

          getDocs(q)
            .then((snapshot) => {
              let themeTags: TagModel[] = [];
              snapshot.forEach((doc) => {
                themeTags.push({
                  tag_id: doc.data().tag_id ?? "",
                  label: doc.data().label ?? "",
                  count: doc.data().count,
                  level: doc.data().level,
                });
              });

              // this._checkCorrectThemes(themeTags);

              this.stampDetailed = {
                ...this.stampDetailed,
                height: this.stampDetailed?.height ?? 0,
                width: this.stampDetailed?.width ?? 0,
                id: this.stampDetailed?.id ?? "",
                _themeTags: themeTags,
              };
              this.stampDetailedTagsLoading = false;
            })
            .catch((error) => {
              console.log("Error getting documents - tags: ", error);
              this.stampDetailedTagsLoading = false;
            });
        } else {
          this.stampDetailedTagsLoading = false;
        }
      })
      .catch((error) => {
        this.stampDetailedTagsLoading = false;
        console.log("Error getting documents - tags: ", error);
      });

    this.stampDetailedTagsLoading = false;
  }

  // if there is only one tag, find it in children if items in variable menuItems and find its parent
  _checkCorrectThemes(themeTags: TagModel[]) {
    let missingParentTag = undefined;
    if (themeTags.length === 1) {
      menutItems.forEach((item) => {
        if (item.childrens.length > 0) {
          item.childrens?.forEach((child) => {
            if (child.tag === themeTags[0].tag_id) {
              missingParentTag = item.tag;
            }
          });
        }
      });
    }
    if (missingParentTag) {
      this.addCollectionTagSeparate(
        this.stampDetailed?.id ?? "",
        missingParentTag
      );
    }
  }

  async fetchStampsAndUpdateCollectionIdxx() {
    const country = "sg";
    const db = getFirestore();
    const colRefCollections = collection(db, "collections");

    try {
      const querySnapshot = await getDocs(query(colRefCollections));

      querySnapshot.forEach(async (doc) => {
        try {
          console.log("Deleting", doc.data().label);
          if (
            doc.data().collection_id[0] === country[0] &&
            doc.data().collection_id[1] === country[1] &&
            doc.data().collection_id[2] === "_"
          ) {
            await deleteDoc(doc.ref);
            console.log("Successfully deleted", doc.data().label);
          }
        } catch (deleteError) {
          console.error("Error deleting document:", deleteError);
        }
      });
    } catch (error) {
      console.error("Error getting documents:", error);
    }
  }

  async fetchStampsAndUpdateCollectionId() {
    const country = "sg";

    console.log("fetchStampsAndUpdateCollectionId - start");
    const db = getFirestore();
    const colRefStamps = collection(db, "stamps");

    const q = query(colRefStamps, where("country", "==", country));
    const querySnapshotCountry = await getDocs(q);

    let stamps: Stamp[] = [];
    querySnapshotCountry.forEach((doc) => {
      const targetName = doc.data().nameEn ?? doc.data().name;
      console.log("***name", targetName, doc.data().id);
      const splitedTargetName5 = targetName.split("$")[0].trim();
      const splitedTargetName4 = splitedTargetName5.split(".")[0].trim();
      const splitedTargetName3 = splitedTargetName4.split("(")[0].trim();
      const splitedTargetName2 = splitedTargetName3.split("-")[0].trim();
      const splitedTargetName1 = splitedTargetName2.split("–")[0].trim();
      const splitedTargetName = splitedTargetName1.split(":")[0].trim();

      stamps.push({
        id: doc.data().id,
        name: splitedTargetName,
        width: doc.data().width!,
        height: doc.data().height!,
        collectionId: doc.data().collectionId,
      });
    });

    const stampsWithSameName = stamps.filter(
      (stamp) => stamps.filter((d) => d.name === stamp.name).length > 1
    );

    // sort stampsWithSameName by name
    stampsWithSameName.sort((a, b) =>
      (a.name ?? "") > (b.name ?? "") ? 1 : -1
    );

    let lastStampName = "";
    let collectionIndex = 0;
    let collectionId = "";

    let promises: any = [];
    let collectionIdsStamps: any = [];

    stampsWithSameName.forEach(async (stamp, j) => {
      if (lastStampName !== stamp.name) {
        collectionIndex++;
        collectionId = country + "_" + collectionIndex;

        // CREATE COLLECTION
        const colRefCollections = collection(db, "collections");
        const x = addDoc(colRefCollections, {
          label: stamp.name,
          count: 0,
          collection_id: collectionId,
        });
        promises.push(x);
        console.log(j, "new col", stamp.name, collectionId);
      }
      lastStampName = stamp.name ?? "";
      collectionIdsStamps[stamp.id] = { id: country + "_" + collectionIndex };
    });

    await Promise.all(promises);

    console.log("collectionIdsStamps", collectionIdsStamps);
    const colRef = collection(db, "stamps");
    const querySnapshot = await getDocs(colRef);
    const batch = writeBatch(db);
    querySnapshot.forEach((doc) => {
      const docRef: DocumentReference = doc.ref;

      if (collectionIdsStamps[doc.data().id]) {
        batch.update(docRef, {
          collectionId: collectionIdsStamps[doc.data().id].id,
        });

        console.log(
          "add collections",
          doc.data().id,
          collectionIdsStamps[doc.data().id].id
        );
      }
    });

    await batch.commit();
  }

  async _fetchTags() {
    const db = getFirestore();
    const colRef = collection(db, "tags");

    const q = query(colRef);
    await getDocs(q)
      .then((snapshot) => {
        let tags: TagModel[] = [];
        snapshot.forEach((doc) => {
          tags.push({
            tag_id: doc.data().tag_id,
            label: doc.data().label,
            count: doc.data().count,
            level: doc.data().level,
          });
        });

        this.tags = tags;
      })
      .catch((error) => {
        console.error("Error getting documents - tags: ", error);
      });
  }

  async loadStamps(params: FilterParamsModel) {
    // this.tagPreviewed = params.tag;
    this._fetchTags();
    this._fetchStamps(params);
  }

  async handleSnapshot(snapshot: QuerySnapshot<DocumentData>) {
    let stamps: Stamp[] = [];

    snapshot.docs.map(async (doc) => {
      const imgLocal =
        doc.data().imgLocal && doc.data().imgLocal !== ""
          ? "/stamps/" +
            doc.data().country +
            "/" +
            doc
              .data()
              .imgLocal.replace("/stamps/", "stamps/")
              .replace("stamps/" + doc.data().country + "/", "")
          : undefined;
      const imgLocalLarge =
        doc.data().imgLocalLarge && doc.data().imgLocalLarge !== ""
          ? "/stamps/" +
            doc.data().country +
            "/" +
            doc
              .data()
              .imgLocalLarge.replace("/stamps/", "stamps/")
              .replace("stamps/" + doc.data().country + "/", "")
          : undefined;

      stamps.push({
        ...this.docDataToStamp(doc),
        imgLocal: imgLocal,
        imgLocalLarge: imgLocalLarge,
      });
    });

    this.stamps = feRandomSort ? randomSort(stamps) : stamps;
  }

  docDataToStamp(doc: DocumentData) {
    const stamp: Stamp = {
      id: doc.data().id,
      _id: doc.data()._id,
      sku: doc.data().sku,
      collectionId: doc.data().collectionId,
      annotation: doc.data().annotation,
      annotationEn: doc.data().annotationEn,
      author: doc.data().author,
      awards: doc.data().awards,
      awardsEn: doc.data().awardsEn,
      designer: doc.data().designer,
      engraver: doc.data().engraver,
      illustrator: doc.data().illustrator,
      noteEn: doc.data().noteEn,
      paper: doc.data().paper,
      perforation: doc.data().perforation,
      motive: doc.data().motive,
      themeDescription: doc.data().themeDescription,
      themeDescriptionEn: doc.data().themeDescriptionEn,
      themeDescriptionShortEn: doc.data().themeDescriptionShortEn,
      themeTitle: doc.data().themeTitle,
      themeTitleEn: doc.data().themeTitleEn,
      formEn: doc.data().formEn,
      printTechniqueEn: doc.data().printTechniqueEn,
      height: doc.data().height,
      width: doc.data().width,
      name: doc.data().name,
      nameEn: doc.data().nameEn,
      country: doc.data().country,
      countryName: doc.data().countryName,
      date: doc.data().date,
      dateWithdrawn: doc.data().dateWithdrawn,
      year: doc.data().year,
      yearFrom: doc.data().yearFrom,
      yearTo: doc.data().yearTo,
      form: doc.data().form,
      countInList: doc.data().countInList,
      countInSeries: doc.data().countInSeries,
      print: doc.data().print,
      printTechnique: doc.data().printTechnique,
      bonding: doc.data().bonding,
      bondingEn: doc.data().bondingEn,
      emission: doc.data().emission,
      emissionString: doc.data().emissionString,
      img: doc.data().img,
      imgLocal: doc.data().imgLocal,
      imgLocalLarge: doc.data().imgLocalLarge,
      url: doc.data().url,
      urlEn: doc.data().urlEn,
      wns: doc.data().wns,
      themeTags: doc.data().themeTags,
      imgLarge: doc.data().imgLarge,
      imgSmall: doc.data().imgSmall,
      collectionTags: doc.data().collectionTags,
      labels: doc.data().labels,
      color: doc.data().color,
      nominal: doc.data().nominal,
      price: doc.data().price,
      photo: doc.data().photo,
      imgHeight: doc.data().imgHeight,
      imgWidth: doc.data().imgWidth,
      timestampField: doc.data().timestampField,
      issuedIn: doc.data().issuedIn,
      denomination: doc.data().denomination,
      reprint: doc.data().reprint,
      catalogs: doc.data().catalogs,
      additionalInfo: doc.data().additionalInfo,
      artists: doc.data().artists,
      plots: doc.data().plots,
      series: doc.data().series,
    };
    return stamp;
  }

  setTagPageIndex(i: number) {
    this._fetchStamps({ tag: this.tagPreviewed, page: i });
  }

  onCollectionImageClick(id: string) {
    if (this.stampDetailed) {
      const stampClicked = this.stampDetailed?._withinCollection?.find(
        (stamp) => stamp.id === id
      );

      const actualWithin = this.stampDetailed?._withinCollection;

      if (stampClicked) {
        this.stampDetailed = {
          ...stampClicked,
          _withinCollection: actualWithin,
        };
        this._fetchStampTags();
      }
    }
  }

  async _fetchStamp(id: string) {
    const db = getFirestore();
    const q = query(
      collection(db, "stamps"),
      where("timestampField", "==", id)
    );
    const snapshot = await getDocs(q);
    this.stampDetailed = snapshot.docs.map((doc) =>
      this.docDataToStamp(doc)
    )[0];

    if (this.stampDetailed === undefined) {
      this.noMatchStamp = true;
    }

    this._fetchStampTags();

    // collectionId is in format cs_123-1, we need to get only cs_1
    if (this.stampDetailed.collectionId) {
      const collectionIdStart = this.stampDetailed.collectionId.split("-")[0];
      this._fetchCollection(collectionIdStart);
      this._fetchCollectionsStamps(this.stampDetailed?.collectionId);
    }
  }

  async _fetchStamps(params: FilterParamsModel) {
    this.loading = true;
    this.stampDetailed = undefined;
    this.collectionPreviewed = undefined;
    // this.stamps = [];
    this.landing =
      params.country === "" &&
      params.countryName === "" &&
      params.tag === "" &&
      this.hash === "";

    if (localJson) {
      this._fetchJSONStamps();
      return;
    }

    const db = getFirestore();
    const colRefStamps = collection(db, "stamps");
    const colRefStamps2Tags = collection(db, "stamps2tags");

    let q;
    if (params.tag) {
      this.list = "theme";
      this.tag = params.tag;
      this.pageIndex = params.page ?? 0;
      // this.stampsTagsIds = [];
      this.noMatchTheme = false;

      if (this.tagPreviewed !== params.tag) {
        this.tagPreviewed = params.tag;
        const qStams2Tags = query(
          colRefStamps2Tags,
          where("tag_id", "==", params.tag)
        );

        let stampsIds: string[] = [];
        await getDocs(qStams2Tags).then((snapshot) => {
          snapshot.forEach((doc) => {
            stampsIds.push(doc.data().stamp_id);
          });
          this.stampsTagsIds = stampsIds;
        });
      }

      if (this.stampsTagsIds.length === 0) {
        this.noMatchTheme = true;
      }

      if (this.stampsTagsIds && this.stampsTagsIds.length > 0) {
        q = query(
          colRefStamps,
          where(
            "_id",
            "in",
            this.stampsTagsIds.slice(
              this.pageIndex * dbLimitThemes,
              (this.pageIndex + 1) * dbLimitThemes
            )
          ),
          orderBy("timestampField", "asc")
        );
      }

      if (q !== undefined) {
        getDocs(q)
          .then((snapshot) => {
            this.handleSnapshot(snapshot);
            return;
          })
          .catch((error) => {
            console.error("Error getting documents stamps: ", error);
            this.loading = false;
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.loading = false;
      }
    } else if (params.country && params.country !== "") {
      this.list = "country";
      q = query(
        colRefStamps,
        where("country", "==", params.country),
        orderBy("id", "asc"),
        limit(dbLimit),
        startAt(dbLimitStart)
      );

      if (q !== undefined) {
        getDocs(q)
          .then((snapshot) => {
            this.handleSnapshot(snapshot);
            return;
          })
          .catch((error) => {
            console.error("Error getting documents stamps: ", error);
            this.loading = false;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    } else {
      // this.list = "hash";
      const dbLimitHash = this.hash === "" ? dbLimitHomepage : dbLimit;

      q = query(
        colRefStamps,
        where("timestampField", "!=", ""),
        orderBy("timestampField", "asc"),
        limit(dbLimitHash),
        startAt(this.hash)
      );
      if (q !== undefined) {
        getDocs(
          query(
            colRefStamps,
            where("timestampField", "!=", ""),
            // where("imageWidth", ">", 200),
            // where("imageHeight", ">", 200),
            orderBy("timestampField", "asc"),
            limit(dbLimitHash),
            startAt(this.hash)
          )
        )
          .then((snapshot) => {
            this.handleSnapshot(snapshot);
            return;
          })
          .catch((error) => {
            console.error("Error getting documents stamps: ", error);
            this.loading = false;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    }

    // this._fetchBgAll();
  }

  /**
   * Fetches all stamps from stamps table where collectionId is given
   */
  private _fetchCollectionsStamps(collectionId: string) {
    const db = getFirestore();
    const colRefStamps = collection(db, "stamps");

    const q = query(colRefStamps, where("collectionId", "==", collectionId));

    getDocs(q)
      .then((snapshot) => {
        let stamps: Stamp[] = [];
        snapshot.forEach((doc) => {
          stamps.push(this.docDataToStamp(doc));
        });

        this.stampDetailed = {
          ...this.stampDetailed!,
          id: this.stampDetailed?.id!,
          width: this.stampDetailed?.width!,
          height: this.stampDetailed?.height!,
          _withinCollection: stamps,
        };
      })
      .catch((error) => {
        console.error("Error getting documents - stamps: ", error);
      });
  }

  /**
   * Fetches information from collection table for given collectionId
   * and store it into previewedCollection in state
   */
  private _fetchCollection(collectionId: string) {
    const db = getFirestore();
    const colRefCollections = collection(db, "collections");

    const q = query(
      colRefCollections,
      where("collection_id", "==", collectionId)
    );

    getDocs(q)
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          this.collectionPreviewed = {
            id: doc.data().collection_id,
            label: doc.data().label,
            count: doc.data().count,
          };
          this.collectionPreviewedLoaded = true;
        });
      })
      .catch((error) => {
        console.error("Error getting documents - collections: ", error);
      });
  }

  private _fetchBgAll() {
    const db = getFirestore();
    const colRefStamps2Colors = collection(db, "stamps2colors");
    const qStamps2Colors = query(colRefStamps2Colors);

    getDocs(qStamps2Colors)
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          this.stamps = this.stamps.map((stamp) => {
            if (stamp.id === doc.data().stamp_id) {
              return {
                ...stamp,
                _bgColor: doc.data().color,
              };
            } else {
              return stamp;
            }
          });
        });
      })
      .catch((error) => {
        console.error("Error getting documents - colors: ", error);
      });
  }

  // Function get stamp_id and label string.
  // New label will be part of atribute labels in stamps table.
  // Stamp will be updated with new array of labels and saved to db.
  async addLabel(label: string, id: string) {
    // Fetch current tag count from table stamps2tags for tag and update it
    const db = getFirestore();
    const qq = query(collection(db, "stamps"), where("id", "==", id));
    const snapshot = await getDocs(qq);
    const currentStampLabels = snapshot.docs[0].data().labels ?? [];

    const updatedLabels = [...currentStampLabels, label];

    const colRef = collection(db, "stamps");

    const q = query(colRef, where("id", "==", id));
    const querySnapshot = await getDocs(q);
    const batch = writeBatch(db);

    querySnapshot.forEach((doc) => {
      const docRef: DocumentReference = doc.ref;

      batch.update(docRef, {
        labels: updatedLabels,
      });
    });

    await batch.commit();
  }

  async addCollectionTagSeparate(id: string, tag: string) {
    const db = getFirestore();
    const colRef = collection(db, "stamps2tags");

    try {
      // Use await for the addDoc function to ensure it completes before proceeding
      await addDoc(colRef, { tag_id: tag, stamp_id: id });

      // Fetch current tag count from table stamps2tags for tag and update it
      const q = query(
        collection(db, "stamps2tags"),
        where("tag_id", "==", tag)
      );
      const snapshot = await getDocs(q);
      const tagCount = snapshot.size;

      const colRefTags = collection(db, "tags");
      const querySnapshot = await getDocs(colRefTags);
      const batch = writeBatch(db);

      querySnapshot.forEach((doc) => {
        const docRefTags: DocumentReference = doc.ref;

        if (doc.data().tag_id === tag) {
          batch.update(docRefTags, {
            count: tagCount,
          });
        }
      });

      await batch.commit();
      console.log("Tags updated successfully");
    } catch (error) {
      console.error("Error adding collection tag separate:", error);
    }
  }

  async saveDimensions(
    ximages: { id: string; width: number; height: number; imgLocal: string }[]
  ) {
    const db = getFirestore();
    const colRef = collection(db, "stamps");
    const images = ximages.map((d) => ({
      ...d,
      imgLocal: d.imgLocal.replace("http://localhost:3000/", ""),
    }));

    try {
      const querySnapshot = await getDocs(colRef);
      const batch = writeBatch(db);

      querySnapshot.forEach((doc) => {
        const docRef: DocumentReference = doc.ref;
        const img = images.find(
          (d) => d.id === doc.data().id && doc.data().countryName !== ""
        );

        console.log(
          "saving",
          doc.data().id,
          img?.imgLocal,
          doc.data().imgLocal,
          img?.width,
          img?.height
        );

        // const imgLo = img?.imgLocal.split("/");
        // const imgLo2 = "/stamps/hu/" + imgLo?.[imgLo.length - 1];

        if (img) {
          batch.update(docRef, {
            imgWidth: img.width,
            imgHeight: img.height,
          });
        }
      });

      await batch.commit();
    } catch (error) {
      console.error("Error saving width and height:", error);
    }
  }

  async loadTags(stampId?: string) {
    const db = getFirestore();
    const colRef = collection(db, "stamps2tags");

    const q = query(colRef, where("stamp_id", "==", stampId));
    const querySnapshot = await getDocs(q);

    let tags: string[] = [];
    querySnapshot.forEach((doc) => {
      tags.push(doc.data().tag_id);
    });

    this.stamps = this.stamps.map((s) => {
      return s.id === stampId
        ? {
            ...s,
            collectionTags: tags,
          }
        : s;
    });
  }

  async addColor(color: string, id?: string) {
    if (id) {
      const db = getFirestore();
      const colRef = collection(db, "stamps2colors");
      addDoc(colRef, { color: color, stamp_id: id });
      console.log("add color", color, id);
    }
  }

  // async addCollectionId(collectionId: string, id?: string) {
  //   console.log("add collectionId", collectionId, id);
  //   if (id) {
  //     const db = getFirestore();
  //     await updateDoc(doc(db, "stamps", id), {
  //       collectionId: collectionId,
  //     });
  //   }
  // }

  addJSONToDb() {
    const db = getFirestore();
    const colRefStamps = collection(db, "stamps");
    // const colRefStamps2Tags = collection(db, "stamps2tags");

    const stampsRaw = lastStamps as Stamp[];
    const stampsMapped = stampsRaw.map((stamp, i) => ({
      ...stamp,
    }));
    stampsMapped.forEach((stamp) => {
      addDoc(colRefStamps, stamp);
      console.log("add-stamps", stamp.id, stamp);

      // stamp.themeTags?.forEach((tag) => {
      //   addDoc(colRefStamps2Tags, { tag_id: tag, stamp_id: stamp.id });
      //   console.log("add-S2T", tag, stamp.id);
      // });
    });
  }

  duplicateParameterToConsoleString() {
    const stampsRaw = lastStamps as Stamp[];

    let lastName = "";
    let stampBeforeNextStampWithName: Stamp | undefined = undefined;

    const stampsArray = stampsRaw.map((stamp, i) => {
      if (stamp.name !== undefined) {
        lastName = stamp.name;
        stampBeforeNextStampWithName = undefined;
      }

      if (stampBeforeNextStampWithName === undefined) {
        for (let j = i + 1; j < stampsRaw.length; j++) {
          if (stampsRaw[j].name !== undefined) {
            stampBeforeNextStampWithName = stampsRaw[j - 1];
            break;
          }
        }
      }

      let emission = undefined;
      if (
        stamp.emission !== undefined &&
        stamp.emissionString &&
        !stamp.emissionString?.includes("; ")
      ) {
        emission = parseInt(
          stamp.emissionString.split(" - ")[1]?.replaceAll(" ", "")
        );
      }

      if (
        emission === undefined &&
        stampBeforeNextStampWithName?.emissionString !== undefined
      ) {
        stampBeforeNextStampWithName.emissionString
          ?.split("; ")
          .forEach((d) => {
            if (d.startsWith(stamp.id)) {
              if (d.includes(" - ")) {
                emission = parseInt(d.split(" - ")[1]?.replaceAll(" ", ""));
              }
            }
          });
      }

      let engraver = undefined;
      if (
        stamp.engraver !== undefined &&
        stamp.engraver &&
        !stamp.engraver?.includes("; ")
      ) {
        engraver = stamp.engraver;
      }

      if (
        engraver === undefined &&
        stampBeforeNextStampWithName?.engraver !== undefined
      ) {
        stampBeforeNextStampWithName.engraver?.split("; ").forEach((d) => {
          if (d.indexOf(stamp.id) !== -1) {
            engraver = d.split(" - ")[1]?.trim();
          }
        });
      }

      let designer = undefined;
      if (
        stamp.designer !== undefined &&
        stamp.designer &&
        !stamp.designer?.includes("; ")
      ) {
        designer = stamp.designer;
      }

      if (
        designer === undefined &&
        stampBeforeNextStampWithName?.designer !== undefined
      ) {
        stampBeforeNextStampWithName.designer?.split("; ").forEach((d) => {
          if (d.indexOf(stamp.id) !== -1) {
            designer = d.split(" - ")[1]?.trim();
          }
        });
      }

      let perforation = undefined;
      if (
        stamp.perforation !== undefined &&
        stamp.perforation &&
        !stamp.perforation?.includes("; ")
      ) {
        perforation = stamp.perforation;
      }

      if (
        perforation === undefined &&
        stampBeforeNextStampWithName?.perforation !== undefined
      ) {
        stampBeforeNextStampWithName.perforation?.split("; ").forEach((d) => {
          if (d.indexOf(stamp.id) !== -1) {
            perforation = d.split(" - ")[1]?.trim();
          }
        });
      }

      return {
        ...stamp,
        id: "cs" + stamp.id,
        name: lastName,
        _id: "cs" + stamp.id,
        timestampField: stamp.timestampField,
        designer: designer,
        perforation: perforation,
        engraver: engraver,
        printTechnique:
          stamp.printTechnique ??
          stampBeforeNextStampWithName?.printTechnique ??
          undefined,
        paper: stamp.paper ?? stampBeforeNextStampWithName?.paper ?? undefined,
        emission: emission ? emission : undefined,
        imgLocal: "/stamps/cs/" + stamp.id + ".jpg",
        emissionString: undefined,
        TF: undefined,
        FDC: undefined,
      };
    });
    console.log(JSON.stringify(stampsArray));
  }

  exportJSONImgToString() {
    const stampsRaw = lastStamps as Stamp[];

    const stampsMapped = stampsRaw.map((stamp) => ({
      ...stamp,
    }));
    let imgHtml = "";
    let themeTags: string[] = [];
    stampsMapped.forEach((stamp) => {
      imgHtml += `<img src="${stamp.img}" />`;
      if (stamp.themeTags) {
        stamp.themeTags.forEach((tag) => {
          if (!themeTags.includes(tag)) {
            themeTags.push(tag);
          }
        });
      }
    });
    console.log(imgHtml);
    console.log(themeTags);
  }

  async operationTags() {
    const db = getFirestore();
    const colRef = collection(db, "tags");

    try {
      const querySnapshot = await getDocs(colRef);

      const batch = writeBatch(db);

      querySnapshot.forEach((doc) => {
        const docRef: DocumentReference = doc.ref;

        batch.update(docRef, {
          count: 0,
        });
      });

      await batch.commit();

      console.log("Ids copied to _id successfully.");
    } catch (error) {
      console.error("Error copying ids:", error);
    }
  }

  async onCopyIdTo_IdClick() {
    const db = getFirestore();
    const colRef = collection(db, "stamps");

    try {
      const querySnapshot = await getDocs(colRef);
      const batch = writeBatch(db);
      querySnapshot.forEach((doc) => {
        const docRef: DocumentReference = doc.ref;
        if (doc.data().country && doc.data().country === "in") {
          batch.update(docRef, {
            imgLocal: doc.data().imgLocal.replace("in/Stamps2013/", "in/"),
          });
        }
      });
      await batch.commit();
      console.log("Ids copied to _id successfully.");
    } catch (error) {
      console.error("Error copying ids:", error);
    }
  }

  async onStampNameUpdate(name: string, id?: string) {
    console.log("onStampNameUpdate", name, id);

    const newName = name.trim();

    if (!id || newName === "") {
      console.error("Invalid ID or name.");
      return; // Exit the function if ID is missing or name is empty.
    }

    try {
      const db = getFirestore();
      const colRef = collection(db, "stamps");

      const q = query(colRef, where("_id", "==", id));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.size === 0) {
        console.log("Document not found in Firestore:", id);
        return;
      }

      const batch = writeBatch(db);
      querySnapshot.forEach((doc) => {
        const docRef: DocumentReference = doc.ref;
        batch.update(docRef, {
          nameEn: newName,
        });
      });

      await batch.commit();
    } catch (error) {
      console.error("Error updating name:", error);
    }
  }

  async addTimestampField() {
    const db = getFirestore();
    const colRef = collection(db, "stamps");

    try {
      const querySnapshot = await getDocs(colRef);

      const batch = writeBatch(db);

      querySnapshot.forEach((doc) => {
        const docRef: DocumentReference = doc.ref;
        const randomString = generateRandomString(12); // Adjust the length as needed

        // if (
        //     doc.data().timestampField === undefined &&
        //     doc.data().countryName !== ""
        //   ) {
        //     batch.update(docRef, {
        //       timestampField: randomString,
        //     });

        //     console.log("add timestampField", doc.data().id, randomString);
        //   }

        if (doc.data().countryName === "niger") {
          batch.update(docRef, {
            country: "ne",
          });
          console.log("add country", doc.data().id, randomString);
        }
      });

      await batch.commit();

      console.log(
        "Timestamp fields edited to random strings for all documents successfully."
      );
    } catch (error) {
      console.error("Error editing timestamp fields:", error);
    }
  }

  // function replace value in field in Firebase if it has a specific value
  // inputs values are hardcoded
  // inputs are collection, column, old value, new value
  async onRenameColumnClick() {
    let collectionName: string = "stamps2tags";
    let fieldName: string = "tag_id";
    let oldValue: string = "heraldics";
    let newValue: string = "heraldry";
    // let collectionName: string = "stamps2tags";
    // let fieldName: string = "tag_id";
    // let oldValue: string = "famous_people";
    // let newValue: string = "people";
    const db = getFirestore();
    const colRef = collection(db, collectionName);

    try {
      const querySnapshot = await getDocs(colRef); // Wait for the querySnapshot

      const batch = writeBatch(db);

      querySnapshot.forEach((doc) => {
        const docRef: DocumentReference = doc.ref;
        const data = doc.data();

        // Check if the old field exists before updating
        if (data[fieldName] === oldValue) {
          batch.update(docRef, {
            [fieldName]: newValue,
          });
        } else {
          console.warn(
            `Document ${doc.id} does not have the field ${fieldName}. Skipping.`
          );
        }
      });

      await batch.commit(); // Wait for the batch to commit

      console.log("Field renamed successfully.");
    } catch (error) {
      console.error("Error renaming field:", error);
    }
  }
}

// Function to generate a random string of specified length
export function generateRandomString(length: number): string {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}
