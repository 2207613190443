import { Button, Grid, ButtonGroup } from "@mui/material";
import { WithLogic, withLogicContext } from "../../App";
import { observer } from "mobx-react";
import { dbLimitThemes } from "../../configs";

interface Props extends WithLogic {}

function ThemeHeading(props: Props) {
  const paginationLength = Math.ceil(
    props.logic.playground().stampsTagsIds.length / dbLimitThemes
  );

  return (
    <>
      <Grid container className="theme-heading">
        {/* <Grid item>
          <h1>
            {
              props.logic
                .playground()
                .tags.find(
                  (d) => d.tag_id === props.logic.playground().tagPreviewed
                )?.label
            }
          </h1>
        </Grid> */}
        {props.logic.playground().list === "theme" &&
          props.logic.playground().stampsTagsIds.length >= 0 && (
            <Grid item>
              <ButtonGroup variant="contained" className="pagination">
                <Button className="label">
                  {
                    props.logic
                      .playground()
                      .tags.find(
                        (d) =>
                          d.tag_id === props.logic.playground().tagPreviewed
                      )?.label
                  }
                </Button>
                <Button
                  disabled={props.logic.playground().pageIndex === 0}
                  className={`arrow-index${
                    props.logic.playground().pageIndex === 0
                      ? " disabled-index"
                      : ""
                  }`}
                  onClick={(e) => {
                    props.logic
                      .playground()
                      .setTagPageIndex(props.logic.playground().pageIndex - 1);
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                >
                  &lt;
                </Button>
                {paginationLength >= 0 &&
                  props.logic.playground().stampDetailed === undefined &&
                  Array.from(
                    { length: paginationLength },
                    (_, index) => index
                  ).map((d, i) => (
                    <Button
                      key={i}
                      disabled={i === props.logic.playground().pageIndex}
                      className={
                        i === props.logic.playground().pageIndex
                          ? "active-index"
                          : "number-index"
                      }
                      onClick={(e) => {
                        props.logic.playground().setTagPageIndex(i);
                        e.stopPropagation();
                        e.preventDefault();
                      }}
                    >
                      {d + 1}
                      <span className="menu-length-info">
                        {" "}
                        / {paginationLength}
                      </span>
                    </Button>
                  ))}
                <Button
                  disabled={
                    props.logic.playground().pageIndex === paginationLength - 1
                  }
                  className={`arrow-index${
                    props.logic.playground().pageIndex === paginationLength - 1
                      ? " disabled-index"
                      : ""
                  }`}
                  onClick={(e) => {
                    props.logic
                      .playground()
                      .setTagPageIndex(props.logic.playground().pageIndex + 1);
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                >
                  &gt;
                </Button>
              </ButtonGroup>
            </Grid>
          )}
      </Grid>
    </>
  );
}

export default withLogicContext(observer(ThemeHeading));
