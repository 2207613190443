import { Box } from "@mui/material";
import { collectionStampWidth } from "../../configs";
import { Stamp } from "../../types/stamps";
interface Props {
  stamp: Stamp;
  onImageClick?: (id: string) => void;
}

function StampImage(props: Props) {
  const { stamp } = props;

  const ww =
    (props.stamp?.imgWidth ?? 1) >= (props.stamp?.imgHeight ?? 1)
      ? collectionStampWidth
      : undefined;

  const hh =
    (props.stamp?.imgWidth ?? 1) <= (props.stamp?.imgHeight ?? 1) && !ww
      ? collectionStampWidth
      : undefined;

  const imgSrc = stamp.imgLocal
    ? stamp.imgLocal.replace("small", "medium")
    : "stamps/" +
        stamp.country +
        "/" +
        stamp.img?.split("/")?.[stamp.img?.split("/").length - 1] ?? stamp.img;

  const fixedImgSrc = imgSrc[0] === "/" ? imgSrc : "/" + imgSrc;

  return (
    <>
      <Box className={``}>
        <img
          src={fixedImgSrc}
          alt={stamp.name}
          loading="lazy"
          width={ww}
          height={hh}
          style={{
            display: "block",
            margin: "0 auto",
            cursor: "pointer",
          }}
          onClick={() => props.onImageClick?.(stamp.id)}
        />
      </Box>
    </>
  );
}

export default StampImage;
