import "/node_modules/flag-icons/css/flag-icons.min.css";
import { Grid, Typography, Box } from "@mui/material";

interface Props {
  onClick?: (hash: string) => void;
}

function StatCountries(props: Props) {
  return (
    <Box style={{ padding: "16px 0 0" }}>
      <Typography variant="h2" className="main-heading-lower">
        Stamps from countries
      </Typography>
      <Grid
        container
        spacing={2}
        className="top-divider"
        justifyContent="center"
        rowSpacing={2}
        columnSpacing={2}
      >
        <Grid item>
          <span className="fi fi-ad"></span> Andorra [8x]
        </Grid>
        <Grid item>
          <span className="fi fi-ax"></span> Aland Islands [42x]
        </Grid>
        <Grid item>
          <span className="fi fi-ca"></span> Canada [54x]
        </Grid>
        <Grid item>
          <span className="fi fi-cz"></span> Czechoslovakia [2571x]
        </Grid>
        <Grid item>
          <span className="fi fi-cy"></span> Cyprus [47x]
        </Grid>
        <Grid item>
          <span className="fi fi-cz"></span> Czech republic [1111x]
        </Grid>
        <Grid item>
          <span className="fi fi-ee"></span> Estonia [391x]
        </Grid>
        <Grid item>
          <span className="fi fi-fr"></span> France [42x]
        </Grid>
        <Grid item>
          <span className="fi fi-de"></span> Germany [150x]]
        </Grid>
        <Grid item>
          <span className="fi fi-hu"></span> Hungary [185x]
        </Grid>
        <Grid item>
          <span className="fi fi-in"></span> India [1524x]
        </Grid>
        <Grid item>
          <span className="fi fi-mc"></span> Monaco [100x]
        </Grid>
        <Grid item>
          <span className="fi fi-nl"></span> Netherland [12x]
        </Grid>
        <Grid item>
          <span className="fi fi-nz"></span> New Zealand [112x]
        </Grid>
        <Grid item>
          <span className="fi fi-no"></span> Norway [196x]
        </Grid>
        <Grid item>
          <span className="fi fi-pl"></span> Poland [181x]
        </Grid>
        <Grid item>
          <span className="fi fi-sm"></span> San Marino [208x]
        </Grid>
        <Grid item>
          <span className="fi fi-sg"></span> Singapore [171x]
        </Grid>
        <Grid item>
          <span className="fi fi-sk"></span> Slovakia [809x]
        </Grid>
        <Grid item>
          <span className="fi fi-si"></span> Slovenia [990x]
        </Grid>
        <Grid item>
          <span className="fi fi-se"></span> Sweden [32x]
        </Grid>
        <Grid item>
          <span className="fi fi-tg"></span> Togo [13x]
        </Grid>
        <Grid item>
          <span className="fi fi-vn"></span> Vietnam [421x]
        </Grid>
      </Grid>
    </Box>
  );
}

export default StatCountries;
