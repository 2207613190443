/**
 * function which return random number between min and max
 */
export function getRandomInt(min: number = 0, max: number = 160): number {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

/**
 * function that remove all items except last n items
 */
export function removeItemsExceptLastNItems<T>(array: T[], n: number): T[] {
  return array.splice(array.length - n, n);
}
/**
 * function that sort array of objects randomly
 */
export function randomSort<T>(array: T[]): T[] {
  return array.sort(() => Math.random() - 0.5);
}
