import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
} from "@mui/material";
import numeral from "numeral";
import moment from "moment";
import BoxClose from "../BoxClose";
import { WithLogic, withLogicContext } from "../../App";
import { confDefault } from "../../conf";
import {
  getCountryNameFromCode,
  isDevelopersMode,
} from "../../utils/string-utils";
import { Stamp } from "../../types/stamps";

export const ENV_FILESERVER = "/s";

interface Props extends WithLogic {
  stamp: Stamp;
  onStampDetailClick?: () => void;
}

function StampPreview(props: Props) {
  let authors = [];

  if (props.stamp.author) {
    authors.push(props.stamp.author);
  }
  if (props.stamp.designer) {
    authors.push(props.stamp.designer);
  }
  if (props.stamp.illustrator) {
    authors.push(props.stamp.illustrator);
  }

  return (
    <div className="stamp-preview" key={props.stamp.id}>
      <Card onClick={props.onStampDetailClick} className="stamp-preview-card">
        <CardHeader
          title={props.stamp.nameEn ?? props.stamp.name}
          subheader={
            props.stamp.date
              ? moment(props.stamp.date, "DD.MM.YYYY").year()
              : props.stamp.year
          }
          className="stamp-preview-card-header"
        />
        <Box className="stamp-preview-scroll-content">
          <CardMedia
            component="img"
            image={
              props.stamp.imgLocal
                ? props.stamp.imgLocal.replace("_small", "_medium")
                : props.stamp.img
            }
            alt={props.stamp.nameEn ?? props.stamp.name}
          />
          <CardContent className="stamp-preview-content">
            {props.stamp.country && (
              <Grid container justifyContent="space-between">
                <Grid item>Country</Grid>
                <Grid item>{getCountryNameFromCode(props.stamp.country)}</Grid>
              </Grid>
            )}

            {props.stamp.width && props.stamp.height && (
              <Grid container justifyContent="space-between">
                <Grid item>Size</Grid>
                <Grid item>
                  {props.stamp.width} x {props.stamp.height} mm
                </Grid>
              </Grid>
            )}

            {props.stamp.printTechnique && (
              <Grid container justifyContent="space-between">
                <Grid item>Print technique</Grid>
                <Grid item>{props.stamp.printTechnique}</Grid>
              </Grid>
            )}

            {props.stamp.emission && (
              <Grid container justifyContent="space-between">
                <Grid item>Emmision</Grid>
                <Grid item>{numeral(props.stamp.emission).format("0,0")}</Grid>
              </Grid>
            )}

            {authors.length > 0 && (
              <Grid container justifyContent="space-between">
                <Grid item>Author</Grid>
                <Grid item>{authors.join(",")}</Grid>
              </Grid>
            )}

            {props.stamp.date && (
              <Grid container justifyContent="space-between">
                <Grid item>Date of issue</Grid>
                <Grid item>{props.stamp.date}</Grid>
              </Grid>
            )}

            {props.stamp.print && (
              <Grid container justifyContent="space-between">
                <Grid item>Print</Grid>
                <Grid item>{props.stamp.print}</Grid>
              </Grid>
            )}

            {props.stamp.themeTags && (
              <Grid container justifyContent="space-between">
                <Grid item>Tags</Grid>
                <Grid item>{props.stamp.themeTags?.join(", ")}</Grid>
              </Grid>
            )}

            {props.stamp.collectionTags && (
              <Grid container justifyContent="space-between">
                <Grid item>Themes</Grid>
                <Grid item>{props.stamp.collectionTags?.join(", ")}</Grid>
              </Grid>
            )}
            {isDevelopersMode() && (
              <>
                {props.stamp.id && (
                  <Grid container justifyContent="space-between">
                    <Grid item>ID</Grid>
                    <Grid item>{props.stamp.id}</Grid>
                  </Grid>
                )}
                {props.stamp._id && (
                  <Grid container justifyContent="space-between">
                    <Grid item>_ID</Grid>
                    <Grid item>{props.stamp._id}</Grid>
                  </Grid>
                )}
              </>
            )}
          </CardContent>
        </Box>
      </Card>
      <BoxClose
        bgColor={confDefault.playground.stampDetail.bgColor}
        onCloseClick={props.onStampDetailClick}
        bottomClose={true}
      />
    </div>
  );
}

export default withLogicContext(StampPreview);
