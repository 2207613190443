import { Chip } from "@mui/material";
import BoxClose from "../BoxClose";
import { confDefault } from "../../conf";
import { WithLogic, withLogicContext } from "../../App";
import { useNavigate } from "react-router-dom";
import { Slide, Grid } from "@mui/material";
import { TAG_COUNT_MIN } from "../../configs";

interface Props extends WithLogic {
  bgColor?: string;
  open: boolean;
  onCloseClick?: () => void;
  onChipClick?: (tag_id: string) => void;
  onWordsLeave?: () => void;
}

function CollectionWords(props: Props) {
  function onChipClick(label: string) {
    navigate(`${label}`);
    if (props.onChipClick) {
      props.onChipClick(label);
    }
  }

  function onWordsLeave() {
    if (props.onWordsLeave) {
      props.onWordsLeave();
    }
  }

  const navigate = useNavigate();

  return (
    <Slide direction="right" in={props.open} mountOnEnter unmountOnExit>
      <div
        className="collection-words"
        style={{
          backgroundColor:
            props.bgColor ?? confDefault.playground.collectionWords.bgColor,
          display: props.open ? "block" : "none",
        }}
        // onMouseLeave={onWordsLeave}
      >
        <Grid container justifyContent="space-between">
          <Grid item>
            <div className="collection-words-title">Themes</div>
          </Grid>
          <Grid item>
            <BoxClose
              bgColor={
                props.bgColor ?? confDefault.playground.collectionWords.bgColor
              }
              onCloseClick={props.onCloseClick}
              bottomClose={false}
            />
          </Grid>
        </Grid>
        <div className="collection-words-content">
          {props.logic
            .playground()
            .tags.filter((tag) => tag.count && tag.count > TAG_COUNT_MIN)
            .map((tag) => (
              <Chip
                className="collection-words-chip"
                key={tag.tag_id}
                sx={{
                  borderRadius: "4px",
                }}
                label={
                  <>
                    {tag.label}
                    <span className="collection-words-count">{tag.count}</span>
                  </>
                }
                onClick={() => onChipClick(`${tag.tag_id}`)}
              />
            ))}
        </div>
      </div>
    </Slide>
  );
}

export default withLogicContext(CollectionWords);
